@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  overflow-x: hidden !important;
}

html {
  scroll-behavior: smooth;
}

a:hover {
  text-decoration: none;
}

/******** VARIABLE **********/
.topBanner .main_banner_text p,
.qkr .qkr-ins .qkr_bg .qkr_section p,
.industries p,
.contact a,
.contact p,
.form p,
.company_info ul li {
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #333;
  font-family: "calibri";
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: #e8e8e8;
}

::-webkit-scrollbar-thumb {
  background: #0556a5;
}

.get_startedBTN,
.topBanner .main_banner_text button.get_started,
.qkr .qkr-ins .qkr_bg .qkr_section button.get_started,
.services button.get_started,
.all-products button.get_started,
.faq button.get_started,
.abtus button.get_started,
.contact button.get_started,
.form button.get_started,
.listProdIMG button.get_started,
.product_details button.get_started,
.product_details button.get_started:hover {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#60c952),
    to(#0556a5)
  );
  background: linear-gradient(to right, #60c952, #0556a5);
  color: #fff !important;
  font-size: 14px;
  font-weight: 600;
  border: 0;
  outline: 0;
  border-radius: 100px;
  padding: 13px 20px;
  text-transform: capitalize;
}

header {
  /* border-top: 5px solid $header-top-color; */
  background: #fff;
  height: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-shadow: 0px 3px 36px -8px rgba(0, 0, 0, 0.17);
  box-shadow: 0px 3px 36px -8px rgba(0, 0, 0, 0.17);
  z-index: 99999999999 !important;
}

header .logo img {
  max-width: 330px;
}

header .menubar ul li {
  margin-right: 10px;
  position: relative;
}

header .menubar ul li a {
  font-size: 14px;
  font-weight: 600;
  color: #000 !important;
  text-transform: uppercase;
}

header .menubar ul li a:hover {
  color: #60c952 !important;
}

header .menubar ul li .submenu {
  position: absolute;
  top: 30px;
  width: 200px;
  background: #fff;
  left: 0;
  z-index: 9999;
  display: none;
  -webkit-transition: 0.6s ease;
  transition: 0.6s ease;
}

header .menubar ul li .submenu li {
  display: block;
  margin-right: 0;
  padding: 5px 0;
  border: 1px dotted #bdbdbd;
}

header .menubar ul li .submenu li:hover {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#60c952),
    to(#0556a5)
  );
  background: linear-gradient(to right, #60c952, #0556a5);
}

header .menubar ul li .submenu li:hover a {
  color: #fff !important;
  padding-left: 2px;
}

header .menubar ul li .submenu li a {
  font-size: 13px;
  font-weight: 600;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

header .menubar ul li:hover .submenu {
  display: block;
}
.banner-r-img img {
  border: 5px solid#fff;
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
  width: 400px;
  object-fit: cover;
  height: 300px;
  box-shadow: 0px 0px 19px -1px rgba(0, 0, 0, 0.5);
}

.topBanner {
  position: relative;
  min-height: 700px;
  display: -webkit-box;
  display: -ms-flexbox;
  background-repeat: no-repeat;
  display: flex;
  background-size: cover;
  background-position: center;
  background-size: cover !important;
  background-position: center !important;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.topBanner::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#60c952),
    to(#0556a5)
  ) !important;
  background: linear-gradient(to left, #60c952, #0556a5) !important;
  opacity: 0.9;
  display: block;
}

/* .topBanner:hover .swiper-button-next {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.topBanner:hover .swiper-button-prev {
  -webkit-transform: scale(1);
  transform: scale(1);
} */

.topBanner .main_banner_text {
  padding-left: 50px;
  z-index: 99;
}

.topBanner .main_banner_text h1 {
  color: #fff;
  margin-bottom: 30px;
  font-size: 38px;
  font-weight: 700;
  text-transform: capitalize;
}

.topBanner .main_banner_text p {
  color: #fff;
  font-size: 14px;
}

.topBanner .main_banner_text button.get_started:hover {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#60c952),
    to(#0556a5)
  ) !important;
  background: linear-gradient(to bottom, #60c952, #0556a5) !important;
}
.swiper-button-next {
  width: 52px !important;
  height: 52px !important;
  background: #fff;
  border-radius: 100px !important;
  color: #000 !important;
  box-shadow: 0px 0px 19px -1px rgba(0, 0, 0, 0.116);
}
.swiper-button-next::after {
  color: #000;
  font-size: 30px !important;
}
.swiper-button-prev {
  width: 52px !important;
  height: 52px !important;
  background: #fff;
  border-radius: 100px !important;
  color: #000 !important;
  box-shadow: 0px 0px 19px -1px rgba(0, 0, 0, 0.116);
}
.swiper-button-prev::after {
  color: #000;
  font-size: 30px !important;
}

/* .topBanner .swiper-button-next,
.topBanner .swiper-button-prev {
  background: #fff;
  border-radius: 100px;
  width: 50px;
  height: 50px;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

.topBanner .swiper-button-next:after,
.topBanner .swiper-button-prev:after {
  font-family: swiper-icons;
  text-transform: none !important;
  letter-spacing: 0;
  text-transform: none;
  font-size: 22px;
  font-variant: initial;
  color: #000;
  line-height: 1;
} */

.swiper-button-next {
  width: 72px;
  height: 72px;
  background: #fff;
  border-radius: 100px;
  color: #000;
}

.mission_vission .mv h2 {
  border-bottom: 5px solid #60c952;
  margin-bottom: 20px;
  font-size: 25px;
  padding: 15px 10px 15px 10px;
  color: #fff;
  text-transform: capitalize;
  position: relative;
}

.mission_vission .mv h2::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 45%;
  height: 100%;
  background: #0556a5;
}

.mission_vission .mv h2::after {
  content: "";
  position: absolute;
  left: 182px;
  top: 0;
  width: 45px;
  height: 100%;
  z-index: 99;
  border-left: 60px solid transparent;
  border-right: 60px solid transparent;
  border-top: 60px solid #fff;
}

.mission_vission .mv p {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #5f5f5f;
  font-family: "Poppins", sans-serif;
}

.mission_vission .mv_img img {
  max-width: 95%;
}

.mission_vission .mv_img::after {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  width: 100%;
  z-index: -1;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#0556a5),
    color-stop(#60c952),
    to(transparent)
  );
  background: linear-gradient(to top, #0556a5, #60c952, transparent);
}

.strt ion-icon {
  font-size: 30px !important;
  border: 2px solid #0556a5;
  width: 30px;
  height: 30px;
  background: #fff;
  padding: 0.5em;
  color: #000;
  border-radius: 100px;
  -webkit-box-shadow: -1px 10px 88px -18px rgba(0, 0, 0, 0.22);
  box-shadow: -1px 10px 88px -18px rgba(0, 0, 0, 0.22);
}



.qkr .qkr-ins .qkr_bg {
  /*  box-shadow: -1px 10px 88px -18px rgba(0,0,0,0.22); */
  /* background: url(../img/bg-shape.jpg); */
  -webkit-box-shadow: -1px 10px 88px -18px rgba(170, 170, 170, 0.22);
  box-shadow: -1px 10px 88px -18px rgba(170, 170, 170, 0.22);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /*  padding: 30px 0 30px 0;
      min-height: 30vh; */
  position: relative;
  margin: 0 auto;
}

.qkr .qkr-ins .qkr_bg::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.1 !important;
}

.qkr .qkr-ins .qkr_bg .qkr_section {
  padding: 0 0px;
  z-index: 99;
}

.qkr .qkr-ins .qkr_bg .qkr_section span {
  position: relative;
}

.qkr .qkr-ins .qkr_bg .qkr_section span::after {
  content: "";
  position: absolute;
  left: -9px;
  top: -8px;
  width: 60px;
  height: 60px;
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#60c952),
    to(#0556a5)
  ) !important;
  background: linear-gradient(to left, #60c952, #0556a5) !important;
  z-index: -1;
  border-radius: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.qkr .qkr-ins .qkr_bg .qkr_section img {
  width: 60px;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.qkr .qkr-ins .qkr_bg .qkr_section h2 {
  margin-bottom: 20px;
  font-size: 25px;
  color: #0556a5 !important;
  font-weight: 700;
  font-family: "Montserrat";
  text-transform: capitalize;
}

.qkr .qkr-ins .qkr_bg .qkr_section p {
  text-align: justify !important;
}

.industries {
  background: #f9f9f9;
}

.industries h2 {
  font-weight: 800;
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.underline {
  margin: 0 auto;
  position: relative;
}

.underline::after {
  content: "";
  position: absolute;
  left: 42.5%;
  bottom: 0;
  width: 166px;
  height: 3px;
  background: #e8e8e8;
  border-radius: 100px;
}

.underline::before {
  content: "";
  position: absolute;
  left: 48%;
  bottom: -1px;
  width: 43px;
  height: 5px;
  background: #60c952;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#60c952),
    to(#1e970e)
  );
  background: linear-gradient(to bottom, #60c952, #1e970e);
  border-radius: 100px;
  z-index: 99;
}

.industryIMGS {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.industryIMGS .pharma {
  margin: 0 2px;
}

.industryIMGS .industry_imgs {
  position: relative;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

.industryIMGS .industry_imgs:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  z-index: 99999;
}

.industryIMGS .industry_imgs:hover::before {
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(0, 0, 0, 0.808)),
    color-stop(rgba(0, 0, 0, 0.664)),
    to(transparent)
  );
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.808),
    rgba(0, 0, 0, 0.664),
    transparent
  );
}

.industryIMGS .industry_imgs img {
  width: 300px;
  height: 50vh;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

.industryIMGS .industry_imgs::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999 !important;
  height: 100%;
  opacity: 0.8;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#0556a5),
    color-stop(#60c952),
    to(transparent)
  );
  background: linear-gradient(to top, #0556a5, #60c952, transparent);
}

.industryIMGS .industry_imgs .industry_content {
  position: absolute;
  left: 10px;
  bottom: 20px;
  z-index: 999;
  color: #fff;
}

.industryIMGS .industry_imgs .industry_content span {
  font-weight: 400;
  font-size: 22px;
  font-style: italic;
}

.industryIMGS .industry_imgs .right-arrow {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 999;
}

.industryIMGS .industry_imgs .right-arrow ion-icon {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    to(#d2d2d2)
  );
  background: linear-gradient(to bottom, #fff, #d2d2d2);
  width: 20px;
  height: 20px;
  border-radius: 100px;
  padding: 1em;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

.industryIMGS .industry_imgs:hover .right-arrow ion-icon {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}


.services h2 {
  font-weight: 800;
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.services h3 {
  font-weight: 800;
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.services p {
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #5f5f5f;
  font-family: "Poppins", sans-serif;
}

.services button.get_started {
  border-radius: 4px;
  padding: 12px 30px;
}

.services .servicesLIST .srvs-gap {
  margin: 0 10px !important;
  overflow: hidden;
}

.services .servicesLIST .srvs-gap:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  overflow: hidden;
}

.services .servicesLIST .srvs-gap img {
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  overflow: hidden;
  width: 100%;
  height: 200px;
}

.services .servicesLIST .srvs-gap h4 {
  font-weight: 500;
  font-size: 15px;
  text-transform: uppercase;
  margin-top: 20px;
  font-family: "Poppins", sans-serif;
}

.client h2 {
  font-weight: 800;
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.all-products {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.all-products::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.8;
}

.all-products h2 {
  font-weight: 800;
  font-size: 30px;
  text-transform: capitalize;
}

.all-products p {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #5f5f5f;
  font-family: "Poppins", sans-serif;
}

img.footer-logo {
  width: 150px;
}

footer {
  background: #000;
  min-height: 450px;
  margin-top: -40px;
  z-index: -1;
  padding: 100px 0 0 0;
  /* &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $black;
    opacity: 0.9;
  } */
}

footer .fbox h2 {
  font-weight: 700;
  font-size: 25px;
  color: #fff;
  position: relative;
}

footer .fbox p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Montserrat", sans-serif;
  color: #c9c9c9;
}

footer .fbox h3 {
  font-weight: 600;
  font-size: 20px;
  color: #fff;
  text-transform: capitalize;
}

footer .fbox ul li {
  margin: 15px 0;
  list-style-type: none;
  color: #eee !important;
}

footer .fbox ul li a {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Montserrat", sans-serif;
  color: #eee;
}

footer .fbox ul li a:hover {
  text-decoration: none;
  color: #60c952;
}

footer .fbox3 ul li a {
  color: #c9c9c9;
}

footer .fbox4 ul li {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* border: 2px solid #fff; */
  width: 40px;
  height: 40px;
  border-radius: 100px;
  margin-right: 10px;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

footer .fbox4 ul li:hover {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#60c952),
    to(#0556a5)
  );
  background: linear-gradient(to left, #60c952, #0556a5);
}

footer .fbox4 ul li ion-icon {
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 24px;
}

.copyright {
  background: #f7f7f741;
  position: relative;
  bottom: 0;
  left: 0;
  margin-top: 50px;
  width: 100%;
  padding-top: 20px;
}

.copyright p {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #b9b9b9;
  font-family: "Poppins", sans-serif;
}

.copyright p a {
  color: #60c952;
}

.copyright p a:hover {
  text-decoration: none;
}

.footer-underline {
  margin: 0 auto;
  position: relative;
}

.footer-underline::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 166px;
  height: 4px;
  background: #f3f3f31e;
  border-radius: 100px;
}

.footer-underline::before {
  content: "";
  position: absolute;
  left: 10px;
  bottom: -1px;
  width: 43px;
  height: 5px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#60c952),
    to(#1e970e)
  );
  background: linear-gradient(to bottom, #60c952, #1e970e);
  border-radius: 100px;
  z-index: 99;
}

.product-category-section {
  overflow: hidden;
  position: relative;
}

.product-category-section::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20%;
  background: black;
  opacity: 0.7;
  z-index: 999;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

.product-category-section:hover::before {
  height: 100%;
}

.product-category-section .details {
  z-index: 999 !important;
  position: absolute;
  left: 0;
  top: 112%;
  opacity: 1;
  width: 100%;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 0 20px;
}

.product-category-section .details h3 {
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  text-transform: capitalize;
  text-align: center;
}

.product-category-section .details p {
  font-weight: 400;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: rgba(255, 255, 255, 0.664);
  text-align: center;
}

.product-category-section .details:hover button.get_started {
  text-decoration: none !important;
}

.product-category-section .details button.get_started {
  border: 1px solid #fff !important;
  border-radius: 2px;
  color: #fff !important;
  z-index: 9999 !important;
  text-transform: capitalize;
  text-decoration: none;
}

.product-category-section .details button.get_started:hover {
  background: #fff;
  color: #000 !important;
}

.product-category-section:hover .details {
  opacity: 1;
  top: 50%;
}

.product-category-section img {
  width: 390px;
  height: 244px;
  -o-object-fit: cover;
  object-fit: cover;
}

.pc-gap {
  margin: 0 5px;
}

.slick-prev:before {
  font-family: slick !important;
  font-size: 47px !important;
  line-height: 1 !important;
  z-index: 99999999 !important;
  position: absolute;
  right: 0 !important;
  opacity: 1 !important;
  top: 0 !important;
  font-weight: 400 !important;
  color: #6e6e6e !important;
}

.slick-next:before {
  font-family: slick !important;
  font-size: 47px !important;
  line-height: 1;
  z-index: 99999999 !important;
  opacity: 1 !important;
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  font-weight: 400 !important;
  color: #6e6e6e !important;
}

.slick-dots {
  display: none !important;
}

/****MOBILE START*******/
@media only screen and (max-width: 600px) {
  .banner-r-img img {
    display: none;
  }
  header .logo img {
    max-width: 267px;
  }
  .topBanner .main_banner_text h1 {
    font-size: 25px;
  }
  .copyright {
    text-align: center;
  }
  .topBanner .main_banner_text {
    padding-left: 0;
  }
  body {
    overflow-x: hidden !important;
  }
  .industryIMGS {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .qkr_bg {
    max-width: 100% !important;
  }
  .industry_imgs img {
    width: 100% !important;
  }
  .copyright {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .menubar {
    display: none;
  }
  .menu {
    display: block !important;
    z-index: 9999999 !important;
  }
  /*******toggle menu**********/
  .toggle {
    width: 60px;
    height: 60px;
    position: fixed;
    right: 20px;
    top: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
  .menu.active {
    margin-left: 0;
  }
  .toggle.active::before {
    -webkit-transform: translateY(0px) rotate(45deg);
    transform: translateY(0px) rotate(45deg);
    -webkit-box-shadow: 0 0 0 #fff;
    box-shadow: 0 0 0 #fff;
  }
  .toggle.active::after {
    -webkit-transform: translateY(0px) rotate(-45deg);
    transform: translateY(0px) rotate(-45deg);
  }
  .toggle::before {
    content: "";
    position: absolute;
    width: 27px;
    height: 3px;
    background: black;
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    -webkit-transition: 0.3s;
    transition: 0.3s;
    -webkit-box-shadow: 0 10px 0 black;
    box-shadow: 0 10px 0 black;
  }
  .toggle::after {
    content: "";
    position: absolute;
    width: 27px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    height: 3px;
    background: black;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  .menu {
    width: 300px;
    height: 100vh;
    background: #fff;
    position: fixed;
    margin-left: -300px;
    left: 0;
    -webkit-box-shadow: 0px 3px 36px -8px rgba(0, 0, 0, 0.17);
    box-shadow: 0px 3px 36px -8px rgba(0, 0, 0, 0.17);
    top: 0;
    overflow-x: hidden;
    z-index: 999999;
    -webkit-transition: 0.3s cubic-bezier(1, 0, 0, 1);
    transition: 0.3s cubic-bezier(1, 0, 0, 1);
  }
  .menu ul li {
    list-style-type: none;
    padding: 15px;
    border: 1px solid #f1f1f1;
  }
  .menu ul li ul li a {
    font-weight: 400;
    font-size: 14px;
  }
  .menu ul li a {
    text-decoration: none;
    color: #313131;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    text-transform: uppercase;
  }
  .slick-prev:before {
    font-family: slick !important;
    font-size: 47px !important;
    line-height: 1 !important;
    z-index: 99999999 !important;
    position: absolute;
    right: -50px !important;
    opacity: 1 !important;
    top: 0 !important;
    font-weight: 400 !important;
    color: #6e6e6e !important;
  }
  .slick-next:before {
    font-family: slick !important;
    font-size: 47px !important;
    line-height: 1;
    z-index: 99999999 !important;
    opacity: 1 !important;
    position: absolute !important;
    left: -50px !important;
    top: 0 !important;
    font-weight: 400 !important;
    color: #6e6e6e !important;
  }
  .product_details button.get_started {
    width: 100% !important;
    margin: 10px 0;
  }
}

/******toggle menu**********/
/********** MOBILE END ***********/
.menu {
  display: none;
}

.clientsec img {
  max-width: 130px;
  height: 168px;
  -o-object-fit: contain;
  object-fit: contain;
}

.fixed-top {
  -webkit-transition: 0.7s ease !important;
  transition: 0.7s ease !important;
}

.megamenu {
  padding: 20px 0;
  background: url(../img/home-banner-01-2.jpg) 0 0 no-repeat !important;
  background-size: cover !important;
  margin: 0 auto !important;
  width: 1200px !important;
  left: -640px !important;
}

.megamenu .col-lg-3 {
  max-width: 300px !important;
}

.megamenu::before {
  content: "" !important;
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  background: #fff !important;
  opacity: 0.9 !important;
  top: 0 !important;
  left: 0 !important;
}

.megamenu li {
  padding-left: 30px !important;
  width: auto !important;
  border: 0 !important;
  margin: -2px 0 !important;
}

.megamenu li:hover a {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.megamenu li a {
  font-weight: 400 !important;
  font-size: 12px !important;
  padding-left: 10px;
}

.megamenu li a:hover {
  text-decoration: none !important;
}

.megamenu li a.megamenu_head {
  font-weight: 600 !important;
  text-transform: uppercase;
  font-size: 14px !important;
  padding-left: 0;
}

.megamenu li ion-icon {
  display: none;
}

.faq {
  background: #f9f9f9;
}

.faq img {
  width: 70px;
  height: 70px;
  -o-object-fit: cover;
  object-fit: cover;
}

.faq button.get_started:hover {
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#60c952),
    to(#0556a5)
  ) !important;
  background: linear-gradient(to left, #60c952, #0556a5) !important;
}

.faq h4 {
  font-weight: 500;
  text-transform: capitalize !important;
}

.abtus {
  background: #f3f3f3;
}

.abtus button.get_started:hover {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#60c952),
    to(#0556a5)
  ) !important;
  background: linear-gradient(to right, #60c952, #0556a5) !important;
}

.abtus button.get_started:hover:hover {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#60c952),
    to(#0556a5)
  ) !important;
  background: linear-gradient(to bottom, #60c952, #0556a5) !important;
}

/****** contact us page ********/
.innerBanner {
  background-size: cover;
  background-position: center;
  min-height: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.innerBanner h2 {
  color: #fff;
  margin-bottom: 30px;
  font-size: 38px;
  z-index: 9;
  padding-left: 50px;
  font-weight: 700;
  text-transform: uppercase;
  /*  border: 1px solid #fff;
    padding: 10px 20px; */
}
.innerBanner h3 {
  color: #fff;
  margin-bottom: 30px;
  font-size: 38px;
  z-index: 9;
  padding-left: 50px;
  font-weight: 700;
  text-transform: uppercase;
  /*  border: 1px solid #fff;
    padding: 10px 20px; */
}

.contact a:hover {
  text-decoration: none;
}

.contact,
.form {
  background: #f3f3f3;
}

.contact h4,
.form h4 {
  font-weight: 800;
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.contact ion-icon,
.form ion-icon {
  font-size: 40px;
  color: #60c952;
}

.contact h6,
.form h6 {
  font-weight: 600;
  font-size: 17px;
}

.contact button.get_started:hover,
.form button.get_started:hover {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#60c952),
    to(#0556a5)
  ) !important;
  background: linear-gradient(to bottom, #60c952, #0556a5) !important;
}

.prodtab li a {
  font-size: 16px;
  font-weight: 400;
  color: #252525;
}

.prodIMG img {
  width: 250px;
  height: 250px;
  object-fit: cover !important;
  object-position: center !important;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

.prodIMG img:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.prodIMG h5 {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 400;
  border: 1px solid #bebebe;
  padding: 15px 5px;
}

.prodIMG:hover h5 {
  background: #0556a5;
  border: 0;
  color: #fff;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

.listProdIMG h5 {
  border: 0 !important;
  font-weight: 500;
}

.listProdIMG button.get_started {
  width: 50%;
  text-decoration: none;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

.listProdIMG button.get_started:hover {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#0556a5),
    to(#60c952)
  );
  background: linear-gradient(to right, #0556a5, #60c952);
  text-decoration: none;
}

.listProdIMG img {
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

.listProdIMG img:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.product_details h5 {
  font-size: 30px;
  color: #0556a5;
  font-weight: 500;
}

.product_details h6 {
  font-size: 24px;
  font-weight: 400;
  color: #6d6d6d;
}

.product_details p {
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 22px;
  color: #5f5f5f;
  font-family: "Poppins", sans-serif;
}

.product_details button.get_started {
  width: 30%;
  border-radius: 3px;
  background: #e8e8e8;
  color: #000 !important;
}

.product_details button.get_started:hover {
  border-radius: 3px;
}

.speifications ul li {
  list-style: disc;
  font-size: 16px;
  font-weight: 400;
  line-height: 38px;
  font-family: "Poppins", sans-serif;
}

.speifications td {
  font-size: 16px;
  font-weight: 400;
  line-height: 38px;
  font-family: "Poppins", sans-serif;
}

.innerBanner {
  position: relative;
}

.innerBanner::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#0556a5),
    to(#60c952)
  );
  background: linear-gradient(to right, #0556a5, #60c952);
  opacity: 0.9;
}

#back2Top {
  width: 40px;
  line-height: 40px;
  overflow: hidden;
  z-index: 999;
  display: none;
  cursor: pointer;
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  position: fixed;
  bottom: 50px;
  right: 50px;
  background-color: #ddd;
  color: #555;
  text-align: center;
  font-size: 30px;
  text-decoration: none;
  z-index: 9999999;
}

#back2Top:hover {
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#60c952),
    to(#0556a5)
  );
  background: linear-gradient(to left, #60c952, #0556a5);
  color: #000;
}

.company_info {
  -webkit-box-shadow: 0px 3px 36px -8px rgba(0, 0, 0, 0.17);
  box-shadow: 0px 3px 36px -8px rgba(0, 0, 0, 0.17);
  padding: 30px;
  background: #fff;
  height: 350px;
  margin-bottom: 20px;
}

.company_info h3 {
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 20px;
  color: #0556a5 !important;
  font-weight: 700;
  font-family: "Montserrat";
  text-transform: capitalize;
}

.company_info ul li {
  font-size: 16px !important;
  line-height: 26px;
}

.sister_comp h3 {
  font-weight: 800;
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.sister_comp .sister_comp_logo {
  background: rgba(241, 241, 241, 0.253);
  -webkit-box-shadow: 0px 3px 36px -8px rgba(0, 0, 0, 0.17);
  box-shadow: 0px 3px 36px -8px rgba(0, 0, 0, 0.17);
  padding: 10px;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 200px;
}

.concern_name {
  margin-top: 20px;
}

.concern_name:hover p {
  background: #0556a5;
  color: #fff;
  border: 0;
}

.concern_name p {
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  font-size: 14px !important;
  font-weight: 500;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  text-align: center;
  border: 0 !important;
  padding: 15px 5px;
}

.principal {
  position: relative;
}

.principal .row {
  margin-bottom: 20px;
}

.principal img {
  width: 200px;
  margin-bottom: 20px;
}

.principal h6 {
  font-weight: 600;
  text-transform: uppercase;
}

.principal p {
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #333;
  font-family: "calibri";
}

.principal::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.85;
}

.abt_contact table {
  display: block !important;
  width: 100% !important;
  overflow-x: auto !important;
}

.abt_contact table tr th {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  background: #0154a2;
  padding: 11px 13px;
  color: #fff;
  width: 23% !important;
  padding-left: 40px;
}

.abt_contact table tr td {
  background: #d9f6ff;
  padding: 17px 40px;
}

.abt_contact table tr td a {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.cl {
  width: 245px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95px;
  border: 1px solid#0252a542;
  margin: 0 5px;
}
button.slick-arrow.slick-prev {
  margin-top: -30px;
}
button.slick-arrow.slick-next {
  margin-top: -30px;
}
/*# sourceMappingURL=style.css.map */
